export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    member() {
      return this.$store.state.member;
    },
  },
};
