export const taskType = [
  {
    key: 'allTask',
    str: '全部任务',
    ico: 'all-task',
  },
  {
    key: 'bySection',
    str: '按部门查看',
    ico: 'by-section',
  },
  {
    key: 'byOne',
    str: '按所属部门成员查看',
    ico: 'by-one',
  },
  {
    key: 'myTask',
    str: '我的任务',
    ico: 'my-task',
  },
];

export const xIco = [
  {
    ico: require('@/assets/image/icon-progress/icon_wks_16@2x.png'),
    str: '未开始',
    color: '#FF6057',
    bgColor: 'rgba(255,96,87,0.1)',
    checked:false
  },
  {
    ico: require('@/assets/image/icon-progress/icon_jxz_16@2x.png'),
    str: '进行中',
    color: '#FFC108',
    bgColor: 'rgba(255,193,8,0.1)',
    checked:false
  },
  {
    ico: require('@/assets/image/icon-progress/icon_ywc_16@2x.png'),
    str: '已完成',
    color: '#1EE48E',
    bgColor: 'rgba(30,228,142,0.1)',
    checked:false
  },
  {
    ico: require('@/assets/image/icon-progress/icon_gd@2x.png'),
    str: '归档',
    color: '#334681',
    bgColor: 'rgba(51,70,129,0.1)',
    checked:false
  },
];

export const tasklevel = [
  {
    value: '生死存亡',
    key: 0,
    icon: require('@/assets/image/progress/addTask/importance1.png'),
  },
  {
    value: '超紧急',
    key: 1,
    icon: require('@/assets/image/progress/addTask/importance2.png'),
  },
  {
    value: '急',
    key: 2,
    icon: require('@/assets/image/progress/addTask/importance3.png'),
  },
  {
    value: '还好',
    key: 3,
    icon: require('@/assets/image/progress/addTask/importance4.png'),
  },
  {
    value: '一般',
    key: 4,
    icon: require('@/assets/image/progress/addTask/importance5.png'),
  },
  {
    value: '你不用理',
    key: 5,
    icon: require('@/assets/image/progress/addTask/importance6.png'),
  },
];
//成员混入 this.member 要先保存在vuex
import memberMixinImport from './memberMixin';
export const memberMixin = memberMixinImport;

export const dailyTaskType = [
  {
    status: '1',
    key: 'important-and-urgent',
    level: '重要且紧急',
    task: [],
    cla: 'iau',
  },
  {
    status: '2',
    key: 'important-not-urgent',
    level: '重要不紧急',
    task: [],
    cla: 'inu',
  },
  {
    status: '3',
    key: 'not-important-but-urgent',
    level: '紧急但不重要',
    task: [],
    cla: 'nibu',
  },
  {
    status: '4',
    key: 'not-important-not-urgent',
    level: '不重要不紧急',
    task: [
      // {
      //   id: '',
      //   title: '',
      //   task_id: '',
      // },
    ],
    cla: 'ninu',
  },
];

export let routerLink = [
  // {
  //   key: 'chat',
  //   link: '/chat',
  //   icon: require('@/assets/image/sidebar/icon_chat@2x.png'),
  //   str: '沟通',
  //   list: [
  //     {
  //       key: 'communicate',
  //       str: '沟通',
  //       ico: require('@/assets/image/sidebar/icon_gt_w@2x.png'),
  //       activeIco: require('@/assets/image/sidebar/icon_gt_b@2x.png'),
  //       link: '/chat/communicate',
  //     },
  //     {
  //       key: 'group',
  //       str: '群里有事',
  //       ico: require('@/assets/image/sidebar/icon_qlys_w@2x.png'),
  //       activeIco: require('@/assets/image/sidebar/icon_qlys_b@2x.png'),
  //       link: '/chat/group',
  //     },
  //     {
  //       key: 'contacts',
  //       str: '联系人',
  //       ico: require('@/assets/image/sidebar/icon_lxr_w@2x.png'),
  //       activeIco: require('@/assets/image/sidebar/icon_lxr_b@2x.png'),
  //       link: '/chat/contacts',
  //     },
  //   ],
  // },
  {
    key: 'task',
    link: '/task',
    icon: require('@/assets/image/sidebar/icon_rw@2x.png'),
    str: '任务管理',
    list: [
      {
        key: 'progress',
        str: '任务进度管理',
        ico: require('@/assets/image/icon-progress/icon_jdgl_bl@2x.png'),
        activeIco: require('@/assets/image/icon-progress/icon_jdgl_w@2x.png'),
        link: '/task/progress',
      },
      {
        key: 'daily',
        str: '日事清',
        ico: require('@/assets/image/icon-progress/icon_rsq_bl@2x.png'),
        activeIco: require('@/assets/image/icon-progress/icon_rsq_w@2x.png'),
        link: '/task/daily',
      },
      {
        key: 'tutorialManage',
        str: '教程管理',
        ico: require('@/assets/image/icon-progress/icon_help_bl@2x.png'),
        activeIco: require('@/assets/image/icon-progress/icon_help_w@2x.png'),
        link: '/task/tutorialManage',
      },
      {
        key: 'ax400',
        str: 'ax400',
        ico: require('@/assets/image/icon-progress/ax400.png'),
        activeIco: require('@/assets/image/icon-progress/ax400_active.png'),
        link: '/task/ax400',
      },
      /*            {
        key: 'help',
        str: 'Help',
        ico: require('@/assets/image/icon-progress/icon_help_w@2x.png'),
        activeIco: require('@/assets/image/icon-progress/icon_help_bl@2x.png'),
        link: '/task/help',
      },
      {
        key: 'disk',
        str: '网盘',
        ico: require('@/assets/image/icon-progress/icon_wp_w.png'),
        activeIco: require('@/assets/image/icon-progress/icon_wp_bl.png'),
        link: '/task/disk',
      },*/
    ],
  },
  {
    key: 'maintain',
    link: '/maintain',
    icon: require('@/assets/image/sidebar/icon_wh@2x.png'),
    str: '维护管理',
    list: [
      {
        key: 'easymaintain',
        str: '易维护',
        ico: require('@/assets/image/sidebar/icon_ywh@2x.png'),
        activeIco: require('@/assets/image/sidebar/icon_ywh@2x.png'),
        link: '/maintain/easymaintain',
      }
    ],
  },
];
